import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-obstacles"></a><h2>Epic Obstacles</h2>
    <a id="walls"></a><h4>Walls</h4>
In addition to the standard types of wall, walls in dungeons can be
made of mithral, adamantine, or even pure force.
    <a id="table-walls"></a><table style={{
      "width": "600px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}> Wall Type
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Typical
Thickness 
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}> Break DC
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>Hardness
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "80px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Hit Points</span><sup>1</sup>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Climb DC</span>
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "150px"
          }}>Paper</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "130px"
          }}>Paper-thin</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "70px"
          }}>1</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "70px"
          }}>-</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1 hp</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Wood</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>6 in.</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>20</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>60 hp</td>
          <td style={{
            "verticalAlign": "top"
          }}>21</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Masonry
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>90
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>20
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Masonry, superiore</td>
          <td style={{
            "verticalAlign": "top"
          }}>1 ft.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>90 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Masonry,
reinforced</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>45
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>180
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Stone, hewn
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3 ft.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>50
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>540 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Stone,
unworked
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>5
ft.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>65
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>900
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Iron
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3 in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>90 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Mithral
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>3
in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>46
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>90
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>70
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Adamantine
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3 in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>56
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>120 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>70
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "150px"
          }}>Magically
treated<sup>2</sup></td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "130px"
          }}>-</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "70px"
          }}>+20</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "70px"
          }}>&times;2</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>&times;2<sup>3</sup></td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>-</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Wall of force
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>70
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Wall
of ice
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
in./lvl
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15+1/in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>0
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>3
hp/in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Wall of iron
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 in./4lvls
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25+2/in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>30 hp/in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Wall
of stone
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
in./4lvls
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>20+2/in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
hp/in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>22
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">1 <span style={{
              "fontStyle": "italic"
            }}>Per 10-foot-by-10-foot section.</span>&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">2 <span style={{
              "fontStyle": "italic"
            }}>These modifiers can be applied to any of
the other wall types.</span>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">3 <span style={{
              "fontStyle": "italic"
            }}>Or an additional 50 hit points, whichever
is greater.</span>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="doors"></a><h4>Doors</h4>
    <a id="table-doors"></a><table style={{
      "width": "600px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}> 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "textAlign": "center"
          }} rowSpan="1" colSpan="2"><span style={{
              "fontWeight": "bold"
            }}>Break DC</span>
          </td>
        </tr>
        <tr valign="bottom">
          <th style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Door Type
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Typical
Thickness 
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>Hardness
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>Hit Points
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "80px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Stuck</span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Locked</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Simple
Wooden
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>1 in.
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>10 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>13
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Good
Wooden
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>1-1&frac12;
in.
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>15 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>16
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>18
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Strong
Wooden
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>2 in.
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>20 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>23
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Stone
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>4 in.
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px"
          }}>60 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>28
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>28
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "150px"
          }}>Iron
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "130px"
          }}>2
in.
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "70px"
          }}>10
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "70px"
          }}>60
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>28
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>28
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Mithral
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2 in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>60 hp.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>40
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>40
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Adamantine
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>2
in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>80
hp.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>60
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>60
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Force
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>n/a
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Portcullis,
Wooden
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>3
in.
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25<sup>1</sup>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25<sup>1</sup></td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Portcullis,
Iron
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>2
in.
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "70px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>60
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>25<sup>1</sup></td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>25<sup>1</sup></td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Portcullis,
Mithral
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>2
in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>60
hp
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30<sup>1</sup>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>30<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Portcullis, Adamantine
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2 in.
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>80 hp
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>40<sup>1</sup>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>40<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Portcullis,
Force
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1
in.
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>n/a
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>50<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="6">1 <span style={{
              "fontStyle": "italic"
            }}>DC to lift. Use appropriate door figure
for breaking.</span>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="obstacles-and-hazards"></a><h4>Obstacles and Hazards</h4>
    <a id="table-obstacles-and-hazards"></a><table style={{
      "width": "600px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "verticalAlign": "middle",
            "width": "170px"
          }}>Obstacle/Hazard
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Effect
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Acid tank</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>1d6
damage per round, or 10d6 per round for total immersion; plus poison
fumes.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Antimagic
field</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Negates
all spells or magical effects. </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Dimensional
anchor trap 
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Blocks
bodily extradimensional travel.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>Hurricane-force
winds</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Ranged
attacks impossible, flight virtually impossible.</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "150px"
          }}>Lava
pit</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "130px"
          }}>2d6
damage per round, or 20d6 per round for total immersion; plus
continuing damage.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle"
          }}>Permanent prismatic
sphere</td>
          <td style={{
            "verticalAlign": "top"
          }}>Requires seven different
spells to bypass.</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Permanent
solid fog</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Move
at one-tenth normal speed, -2 penalty on attack and damage (good when
coupled with incorporeal monsters).</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle"
          }}>Permanent wall of force</td>
          <td style={{
            "verticalAlign": "top"
          }}>Blocks most spells and
ethereal travel, can&#8217;t be dispelled.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Three-dimensional
dungeons</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Levitation/flying
required to move between areas.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Unconnected
rooms</td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Teleportation
required to move between areas.</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>Variable
gravity</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>As
reverse gravity, but direction random each round.</td>
        </tr>
      </tbody>
    </table>
    <a id="slimes-molds-and-fungi"></a><h4>Slimes, Molds, and Fungi</h4>
    <p>For purposes of spells and other special effects, all slimes,
molds, and fungi are treated as plants. Like traps, dangerous slimes
and molds have Challenge Ratings, and characters earn experience points
for encountering them.</p>
    <p>
      <a id="flux-slime"></a><span style={{
        "fontWeight": "bold"
      }}>Flux Slime (CR 21):</span>
      <p>{`Flux slime appears as a clear, viscous liquid that seeps from some
unseen origin point. This origin point is extradimensional, so the
slime may even appear in midair. As the slime flows, it settles and
fills the area around the origin point. `}</p></p>
    <p>Flux slime seems to be an inert substance, devoid of
sentience. It is not caustic or toxic, but it radiates an antimagic
field within a radius of 10 feet. This antimagic field has a caster
level of 21. Any quantity of slime that is removed from the main mass
yellows and hardens in a matter of minutes, turning into a flaky
material that will not adhere to anything. </p>
    <p>In reality, flux slime is a growth with a ravenous appetite
for magical forces. It is a natural draining phenomenon: Magical energy
drains through the origin point in one direction in exchange for the
residue on the far side. The antimagic field a flux slime generates is
actually the byproduct of the consumption of magical energy. </p>
    <p>In addition to the antimagic field&#8217;s effects, magic items that
come into contact with flux slime permanently lose their magical
abilities; creatures with spell-like or super-natural abilities that
come into contact with it take 2d6 points of temporary Constitution
damage per round while it devours flesh; creatures without such
abilities are immune to this effect. </p>
    <p>On the first round of contact, the slime can be scraped off a
creature, but after that it must be frozen, burned, or cut away
(dealing damage to the victim as well). Extreme cold, heat, or sunlight
destroys a patch of flux slime. </p>
    <p>When destroyed, a patch of slime releases the byproducts of
its magical digestion in a dangerous burst that radiates out 50 feet.
All creatures caught in this burst are subject to some random and
permanent transmutation effect, as generated on the table below. Each
burst generates one of these effects. Creatures may resist this effect
with a Fortitude saving throw (DC 29).
    </p>
    <a id="table-flux-slime-destruction-burst"></a><table style={{
      "width": "600px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "verticalAlign": "middle",
            "width": "10%"
          }}>d%
          </th>
          <th style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Result
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>01-10&nbsp;&nbsp;
&nbsp;&nbsp; 
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Blindness
(as blindness/deafness spell)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>11-16
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Cursed
(as bestow curse spell; -4 enhancement penalty on attack rolls, saving
throws, ability checks, and skill checks)</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>17-26
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Deafness
(as blindness/deafness spell)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px"
          }}>27-32
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px"
          }}>Disintegrate
(subject is destroyed by a disintegrate spell)
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "150px"
          }}>33-40
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle",
            "width": "130px"
          }}>Etherealness
(as etherealness spell)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle"
          }}>41-48
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Gaseous (as gaseous form
spell)</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>49-54
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Iron
body (as iron body spell)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle"
          }}>55-60
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Petrification (as flesh to
stone spell)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>61-68
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Plane
shift (subject instantly transports to a random plane)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "middle",
            "width": "150px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>69-74
          </td>
          <td style={{
            "verticalAlign": "middle",
            "width": "130px",
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Polymorph
(as polymorph other spell; choose form randomly)</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)",
            "verticalAlign": "middle"
          }}>75-80
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Reverse
gravity (flux slime becomes the center of a reverse gravity spell).</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>81-88
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Teleport (each subject
teleports to a different,&nbsp; random location)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>89-94
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Temporal
stasis (as temporal stasis spell)</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>95-100
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Reverse aging (subject
gets younger each year, disappearing at moment of &#8220;birth&#8221;) 
          </td>
        </tr>
      </tbody>
    </table>
    <p>After the burst, the extradimensional origin point is sealed.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      